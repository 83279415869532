import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { SnackbarProvider, useSnackbar } from "notistack";
import { getUser, isLoggedIn, logout } from "./src/services/auth";
import { navigate } from "gatsby";

const darkTheme = createTheme({
  palette: {
    mode: "light",
  },
});

function Main({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  document.addEventListener(
    "GraphQLEvent",
    function (e) {
      // console.log("e", e);
      if (e.errors.filter((e) => e?.message == "Invalid token.").length > 0) {
        logout(() => location.reload());
      }else{
        enqueueSnackbar("Something wrong with backend!", { variant: "error" });
      }
    },
    false
  );
  return <> {children}</>;
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={darkTheme}>
    <SnackbarProvider maxSnack={3}>
      <Main>{element}</Main>
    </SnackbarProvider>
  </ThemeProvider>
);
