export const isBrowser = () => typeof window !== "undefined";

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {};

export const setUser = (user) =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user));

export const removeUser = () => window.localStorage.removeItem("gatsbyUser");

export const handleLogin = ({ jwt, user }) => {
  setUser({ ...user, token:jwt });
};

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.username;
};

export const logout = (callback) => {
  setUser({});
  callback();
};
