// src/gatsby-plugin-apollo/client.js
import fetch from "isomorphic-fetch";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "@apollo/client/link/context";
import { GRAPHQL_PATH } from "../constants";
import { getUser } from "../services/auth";

const authLink = setContext((_, { headers }) => {
  const user = getUser();
  const token = user ? user.token : null;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export let evt = null;
if (typeof window !== `undefined`) {
  // or typeof document !== 'undefined'
  // your code that uses global objects here
  evt = document.createEvent("Event");
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log("graphQLErrors", graphQLErrors);
    if (evt) {
      evt.initEvent("GraphQLEvent", true, true);
      evt.errors = graphQLErrors;
      document.dispatchEvent(evt);
    }
  }
  if (networkError) {
    console.log("networkError", networkError);
  }
});


const httpLink = new HttpLink({
  uri: GRAPHQL_PATH,
  fetch,
});

const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default client;
